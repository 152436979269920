import Login from './Login/Login';
import { HomePage } from './Homepage/Homepage';
import { Cart } from './Cart/Cart';
import { CartPublic } from './CartPublic/CartPublic';
import { CartPrivate } from './CartPrivate/CartPrivate';
import { LuckyRaw } from './LuckyRaw/LuckyRaw';
import LiveStream from './LiveStream/LiveStream';
import { LuckyRawCustomers } from './LuckyRawCustomers/LuckyRawCustomers';
import { LuckyRawWinners } from './LuckyRawWinners/LuckyRawWinners';
import { View360 } from "./View360/View360";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    Login,
    HomePage,
    Cart,
    CartPublic,
    CartPrivate,
    LiveStream,
    LuckyRaw,
    LuckyRawCustomers,
    LuckyRawWinners,
    View360
}