export const API = {
  LOGIN: "api/TokenAuth/Authenticate",
  GET_USER: "api/services/app/Session/GetCurrentLoginInformations",
  SIGN_UP: "api/services/app/Account/RegisterAndSendOtp",
  VERIFY_OTP: "api/services/app/Account/ActivationAccountByOtp",
  VERIFY_FORGOT_PASSWORD_OTP: "api/services/app/Account/CheckOtp",
  RESET_PASSWORD: "api/services/app/Account/SendPasswordResetCode",
  UPDATE_PASSWORD: "api/services/app/Account/MobileResetPassword",
  GET_PROJECTS: "api/services/app/MobileProjects/GetAll?",
  GET_BANNERS: "api/services/app/Banners/GetAll?IsShowBannerFilter=1&Sorting=order",
  GET_PROJECT_DETAIL_GROUP: "api/services/app/ProjectFiles/GetAll?",
  GET_CUSTOMER: "api/services/app/Customers/GetAll?",
  GET_DETAIL_CUSTOMER: "api/services/app/Customers/GetCustomerForView",
  GET_AGENTS: "api/services/app/Agents/GetAll",
  GET_EMPOYLEES: "api/services/app/Bookings/GetAllUserForLookupTable?",
  CREATE_ALLOCATE: "api/services/app/Customers/AllocateSave",

  GET_BOOKINGS: "api/services/app/Bookings/GetAll?",
  GET_DETAIL_BOOKING: "api/services/app/Bookings/GetBookingForView",
  DELETE_BOOKING: "api​/services​/app​/Bookings​/Delete",
  UPLOAD_BOOKING_FILE: "api/services/app/Bookings/UploadBookingFiles?",
  CREATE_BOOKING: "api/services/app/Bookings/CreateOrEdit",
  GET_PROPERTY_AND_INFO: "api/services/app/Properties/GetAllPropertiesAndInformationInWebsite?",
  GET_PRIORITY_LIST: "api/services/app/Properties/GetPropertiesByProjectId",
  SCAN_BOOKING_QRCODE: "api/services/app/Bookings/ScanBookingQRCode",
  SCAN_SALE_QRCODE: "api/services/app/Bookings/ScanToCheckSaleQRCode",
  SCAN_CHECK_BOOKING_QRCODE: "api/services/app/Bookings/ScanToCheckBookingQRCode",
  CONFIRM_OTP_BOOKING_CODE: "api/services/app/Bookings/ConfirmOTPBookingCode",
  CREATE_BOOKING_RETURN_ID: "api/services/app/Bookings/InsertBookingAndReturnId",
  CREATE_CUSTOMER: "api/services/app/Customers/CreateOrEdit",
  UPLOAD_CUSTOMER_FILE: "/api/services/app/Customers/UploadAttachments",

  GET_NEWS: "api/services/app/News/GetAll?",
  GET_DETAIL_NEWS: "api/services/app/News/GetNewsForView",

  GET_GENERAL_INFORMATION: "api/services/app/TenantDashboard/GetGeneralInformation",
  GET_LINK_LIVE_STREAM_AND_VIEW_360: "api/services/app/ProjectFiles/GetAllVideoLiveStreamAndView360LiveStream",

  GET_GIFT: "api/services/app/ETickets/GetListFilePrintCustomerWasSent",
  GET_CUSTOMER_WIN: "api/services/app/ETickets/GetListTicketWasSent?Type=3",
  SAVE_CUSTOMER_WIN: "api/services/app/ETickets/SendTicketToCustomers",

  // LUCKY DRAW
  GET_ETICKETS: "api/services/app/ETicketCustomers/GetGiftByOpenSale?openSaleId=3",
  GET_LIST_CUSTOMER_LUCKY_WHEELS: "api/services/app/ETicketCustomers/GetListCustomerToRandomBonusByOpenSale?openSaleId=3",
  GET_ETICKET_CUSTOMER: "api/services/app/ETicketCustomers/GetSpinBonus?openSaleId=3",
  GET_LIST_CUSTOMER_WINNER: "api/services/app/ETickets/GetListBookingCustomerWasPrized?Type=3&OpenSaleId=3&SkipCount=0&MaxResultCount=50"
};
