import axios from "axios";
import { ENVIROMENT } from "commons/utils/enviroment";
import cookie from "js-cookie";
const environment = ENVIROMENT.API;


const instance = axios.create({
  timeout: 25000,
  baseURL: environment,
  headers: { "content-type": "application/json" }
});
const request = async ({ ...options }) => {

  const onSuccess = async response => {
    return response.data;
  };
  const onError = error => {
    return error.response || error.message;
  };
  const token = cookie.get("token");
  if (token) {
    instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
  instance.interceptors.response.use(response => {
    return response;
  }, error => {
    if (error.response.status === 401) {
        // logout
    }
    return Promise.reject(error);
  });
  return instance(options)
    .then(onSuccess)
    .catch(onError);
};
const get = async endPoint => {
  const response = await request({
    method: "GET",
    url: endPoint
  });
  return response;
};
const post = async (endPoint, data, config) => {
  const response = await request({
    method: "POST",
    url: endPoint,
    data,
    headers: config
  });
  return response;
};
const put = async (endPoint, data) => {
  const response = request({
    method: "PUT",
    url: endPoint,
    data,
  });

  return response;
};
const _delete = async (endPoint, params) => {
  const response = await request({
    method: "DELETE",
    url: endPoint,
    params,
  });

  return response;
};

export const httpService = {
  get,
  post,
  put,
  delete: _delete,
};
