export default {
  REQUEST_LOGIN: "REQUEST_LOGIN",
  REQUEST_LOGIN_SUCCESS: "REQUEST_LOGIN_SUCCESS",
  REQUEST_LOGIN_FAILED: "REQUEST_LOGIN_FAILED",

  REQUEST_GET_INFO_USER: "REQUEST_GET_INFO_USER",
  REQUEST_GET_INFO_USER_SUCCESS: "REQUEST_GET_INFO_USER_SUCCESS",
  REQUEST_GET_INFO_USER_FAILED: "REQUEST_GET_INFO_USER_FAILED",

  REQUEST_LOGOUT: "REQUEST_LOGOUT",

}