import { useState, useEffect, useCallback } from "react";
import services from "commons/apis/index";

const useGetCustomerWinner = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const loadMoreData = useCallback(async () => {
    setLoading(true);
    try {
      const { items } = await services.getCustomerWinner();
      setData(items);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [])
  useEffect(() => {
    loadMoreData();
  }, []);
  return {
    data: data ?? [],
    loading,
    loadMoreData
  };
};
export default useGetCustomerWinner;
