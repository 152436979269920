import ActionTypes from 'services/constants';
import cookies from "js-cookie"


/**
 * Initial State of the login of the redux state.
 * @type {Object}
 */
const user = cookies.get("user");
let data = user && JSON.parse(user);
const initialState = !!data ? {
  requesting: false,
  message: '',
  data,
  error: false,
  type: '',
  isLoggedIn: true,
} : {
  requesting: false,
  message: '',
  data: {},
  error: false,
  type: '',
  isLoggedIn: false,
}

const GetInfoReducer = (state = initialState, action) => {
  console.log("action.data", action.data?.result?.user)
  switch (action.type) {
    case ActionTypes.REQUEST_GET_INFO_USER:
      return {
        ...state,
        requesting: true,
        message: action.message,
        type: action.type,
        isLoggedIn: true,
      };
    case ActionTypes.REQUEST_GET_INFO_USER_FAILED:
      return {
        ...state,
        requesting: false,
        error: true,
        type: action.type,
      };
    case ActionTypes.REQUEST_GET_INFO_USER_SUCCESS:
      return {
        // ...state,
        requesting: false,
        error: false,
        message: action.message,
        data: action.data?.result?.user,
        type: action.type,
        isLoggedIn: true,
      };
    case ActionTypes.REQUEST_LOGOUT:
      return {
        ...state,
        message: action.message,
        data: null,
        type: action.type,
      };
    default:
      return state;
  }
};

export default GetInfoReducer;
