import React, { Fragment,useContext } from "react";
import Header from 'commons/components/Header/Header'
import { AuthContext } from "context/AuthContext";

import "./Cart.less";
import { useNavigate } from 'react-router-dom';
import useGetAgents from "commons/hooks/useGetAgents";
import cartEmptySvg from "./cartEmptySvg.svg"
export const Cart = () => {
	let navigate = useNavigate();
	const { user } = useContext(AuthContext);
  console.log(user);
	const { data: dataAgents, loading: loadingGetAgent } = useGetAgents();
	const goLink = (item) => {
		navigate("/cart-private/" + item.id);
	}
	const displayDisplayEstate = (
		dataAgents?.map(item => (
			<div key={item.agent.id} onClick={() => goLink(item.agent)} className='item'>
				<h6>{item.agent.name}</h6>
			</div>
		))
	)
	const renderEmpty = (
		<div className='content-empty'>
			<img src={cartEmptySvg} className="empty-image" />
			<div className="empty-elips"></div>
			<p>Danh sách giỏ hàng trống !</p>
		</div>
	)
	return (
		<Fragment>
			<div className="container-fix">
				<div className="body-container">
					<Header></Header>
					<div className="main animate__animated animate__fadeIn" id="list-estate-page">
						<div className="container" >
							{dataAgents.length > 0 && <h6>Vui lòng chọn sàn muốn giao dịch</h6>}
							<div className={dataAgents.length === 0 ? "estate-list-empty" : "estate-list"}>
								{dataAgents.length === 0 ? renderEmpty : loadingGetAgent ? "" : displayDisplayEstate}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};
