import React from 'react';
import { List, Modal } from 'antd';

const LuckyCustomerModal = ({ ...props }) => {
  return (
    <Modal
      {...props}
      footer={null}
      bodyStyle={{
        width: '100%',
        height: 600,
        overflow: 'auto',
      }}
      width={1280}
      className="modal-lucky"
      maskClosable={false}
      title="Danh sách khách hàng tham gia vòng quay may mắn"
      centered={true}
      maskStyle={{color: "#fff"}}
    >
      <List
        dataSource={props?.data}
        renderItem={(item, index) => (
          <List.Item key={index}>
            <p>{`${index + 1}. BO-${item?.bookingId?.toString().padStart(6, '0')}`}</p>
            <p>{item?.fullName}</p>
            <p>{item?.agentName}</p>
          </List.Item>
        )}
      />
    </Modal>
  )
}
export default LuckyCustomerModal