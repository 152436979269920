import React, { Fragment, useEffect, useState } from "react";
import Header from 'commons/components/Header/Header'
import Iframe from 'react-iframe';
import cookie from 'js-cookie'

import "./View360.less";

export const View360 = () => {
  const [data, setData] = useState({});
  useEffect(() => {
    const data = cookie.get("view-360") && JSON.parse(cookie.get("view-360"));
    console.log(data);
    setData(data);
  }, []);
  return (
    <Fragment>
      <div className="container-fix">
        <div className="body-container">
          <Header></Header>
          <div className="main dark-bg animate__animated animate__fadeIn" id="list-room-page">
            <Iframe url={data?.link ?? "https://momento360.com/e/u/ae91a415efaa4245911aa20eaaafe095?utm_campaign=embed&utm_source=other&heading=-17.8&pitch=-3&field-of-view=75&size=medium"}
              position="relative"
              width="100%"
              id="myId"
              className="myClassname"
              height="100%"
              display="initial"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};
