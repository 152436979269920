import React from 'react';
import { Modal, List } from 'antd';
const LuckyWinnerModal = ({ ...props }) => {
  return (
    <Modal {...props}
      footer={null}
      bodyStyle={{
        width: '100%',
        height: 600,
        overflow: 'auto',
      }}
      width={1280}
      className="modal-lucky"
      maskClosable={false}
      title="Chúc mừng các khách hàng đã may mắn trong vòng quay may mắn"
      centered={true}
    >
      <List
        dataSource={props?.data}
        renderItem={(item, index) => (
          <List.Item key={index}>
            <p>{`${index + 1}. BO-${item?.bookingId?.toString().padStart(6, '0')}`}</p>
            <p>{`${item?.customerFirstName} ${item?.customerLastName}`}</p>
            <p>{item?.filePrintName}</p>
          </List.Item>
        )}
      />
    </Modal>
  )
}
export default LuckyWinnerModal