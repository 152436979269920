import React, { Fragment } from "react";
import  Header  from 'commons/components/Header/Header'
import "./LuckyRawWinners.less";
export const LuckyRawWinners = () => {
    const data =  [
        {
            id: 0,
            host: "GotecLand 3205- 2PN",
            name: 'Trần Tiến Phong',
            estate: 'Sàn 1'
        },
        {
            id: 1,
            host: "GotecLand 3205- 2PN",
            name: 'Trần Tiến Phong',
            estate: 'Sàn 1'
        },
        {
            id: 2,
            host: "GotecLand 3205- 2PN",
            name: 'Trần Tiến Phong',
            estate: 'Sàn 1'
        },
        {
            id: 3,
            host: "GotecLand 3205- 2PN",
            name: 'Trần Tiến Phong',
            estate: 'Sàn 1'
        },
        {
            id: 4,
            host: "GotecLand 3205- 2PN",
            name: 'Trần Tiến Phong',
            estate: 'Sàn 1'
        },
        {
            id: 5,
            host: "GotecLand 3205- 2PN",
            name: 'Trần Tiến Phong',
            estate: 'Sàn 1'
        },
        {
            id: 6,
            host: "GotecLand 3205- 2PN",
            name: 'Trần Tiến Phong',
            estate: 'Sàn 1'
        },
        {
            id: 7,
            host: "GotecLand 3205- 2PN",
            name: 'Trần Tiến Phong',
            estate: 'Sàn 1'
        },
        {
            id: 8,
            host: "GotecLand 3205- 2PN",
            name: 'Trần Tiến Phong',
            estate: 'Sàn 1'
        },
        {
            id: 9,
            host: "GotecLand 3205- 2PN",
            name: 'Trần Tiến Phong',
            estate: 'Sàn 1'
        }
    ]
    const displayRow = (
        data.map((item,index )=> (
            <div className='item'>
                <span className='index'>{index+1}.</span>
                <span>{item.host}</span><span>- {item.name}</span>
                <span>{item.estate}</span>
        </div>
        ))
    )
    return (
        <Fragment>
            <div className="container-fix">
              <div className="body-container">
                <Header></Header>
                <div class="main dark-bg-img customers-lucky-page animate__animated animate__fadeIn" id="customers-page">
                    <div class="container" >
                        <h5>Chúc mừng khách hàng may mắn</h5>
                        <h6>Chúc mừng các khách hàng đã may mắn trong vòng quay may mắn </h6>
                        <div class="customer-list">
                            {displayRow}
                        </div>
                    </div>
                </div>
              </div>
            </div>
        </Fragment>
    );
};
