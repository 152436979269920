import jwt from "jsonwebtoken";
import cookie from "js-cookie";
export const setToken = ({ token }) => {
  try {
    const { exp } = jwt.decode(token);
    const expires = new Date(exp * 1000);
    cookie.set("token", token, { expires });
  } catch (error) {
    // useHihistorystory.push("/login");
  }
};
export const setTokenUser = (user) => {
  cookie.set("user", JSON.stringify(user));
};
export const getTokenUser = () => {
  let user = cookie.get("user");
  return user != null ? JSON.parse(user) : null;
}
export const logout = (redirect = "/") => {
  cookie.remove("token");
  // to support logging out from all windows
  window.localStorage.setItem("logout", Date.now());
  // Router.push(`/login?r=${encodeURIComponent(redirect)}`);s
};