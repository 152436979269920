import ActionTypes from 'services/constants';

/**
 * Initial State of the login of the redux state.
 * @type {Object}
 */
const initialState = {
  requesting: false,
  message: '',
  data: {},
  error: false,
  type: '',
};

const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.REQUEST_LOGIN:
      return {
        ...state,
        requesting: true,
        message: action.message,
        type: action.type,
      };
    case ActionTypes.REQUEST_LOGIN_FAILED:
      return {
        ...state,
        requesting: false,
        error: true,
        type: action.type,
      };
    case ActionTypes.REQUEST_LOGIN_SUCCESS:
      return {
        // ...state,
        requesting: false,
        error: false,
        message: action.message,
        data: action.data,
        type: action.type,
      };
    default:
      return state;
  }
};

export default LoginReducer;
