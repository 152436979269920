import { httpService } from "./services";
import { API } from "./endPoint";
const login = async form => {
  const response = await httpService.post(API.LOGIN, form);
  if (!response) {
    throw new Error(`${response}`);
  }
  return response;
};
const getUser = async () => {
  const response = await httpService.get(API.GET_USER);
  if (!response) {
    return [];
  }
  return response;
};
const signup = async form => {
  const response = await httpService.post(API.SIGN_UP, form);
  if (!response) {
    return false;
  }
  return response;
};
const verifyOtp = async form => {
  const response = await httpService.post(API.VERIFY_OTP, form);
  if (!response) {
    return false;
  }
  return response;
};
const verifyForgotPasswordOtp = async ({ opt, userEmail }) => {
  const url = `${API.VERIFY_FORGOT_PASSWORD_OTP}?opt=${opt}&userEmail=${userEmail}`;
  const response = await httpService.post(url);
  if (!response) {
    return false;
  }
  return response;
};

const resetPassword = async form => {
  const response = await httpService.post(API.RESET_PASSWORD, form);
  if (!response) {
    return false;
  }
  return response;
};
const updatePassword = async form => {
  const response = await httpService.post(API.UPDATE_PASSWORD, form);
  if (!response) {
    return false;
  }
  return response;
};

const getProjects = async model => {
  const { page, count } = model;
  let url_ = API.GET_PROJECTS;
  if (page === null) {
    throw new Error("The parameter 'page' cannot be null.");
  } else if (page !== undefined) {
    url_ += `SkipCount=${encodeURIComponent(`${page * 10}`)}&`;
  }
  if (count === null) {
    throw new Error("The parameter 'count' cannot be null.");
  } else if (count !== undefined) {
    url_ += `MaxResultCount=${encodeURIComponent(`${count}`)}&`;
  }
  url_ = url_.replace(/[?&]$/, "");
  const response = await httpService.get(url_);
  if (response.error) {
    return [];
  }
  return response.result;
};
const getBanner = async () => {
  const response = await httpService.get(API.GET_BANNERS);
  if (response.error) {
    return [];
  }
  return response.result;
};
const getProjectDetailByGroup = async ({
  page,
  count,
  projectId,
  typeFilter,
  subTypeFilter,
}) => {
  let url = API.GET_PROJECT_DETAIL_GROUP;
  if (page === null) {
    throw new Error("The parameter 'page' cannot be null.");
  } else if (page !== undefined) {
    url += `SkipCount=${encodeURIComponent(`${page * 10}`)}&`;
  }
  if (count === null) {
    throw new Error("The parameter 'count' cannot be null.");
  } else if (count !== undefined) {
    url += `MaxResultCount=${encodeURIComponent(`${count}`)}&`;
  }
  if (projectId === null) {
    throw new Error("The parameter 'ProjectIdFilter' cannot be null.");
  } else if (projectId !== undefined) {
    url += `ProjectIdFilter=${encodeURIComponent(`${projectId}`)}&`;
  }
  if (typeFilter === null) {
    throw new Error("The parameter 'TypeFilter' cannot be null.");
  } else if (typeFilter !== undefined) {
    url += `TypeFilter=${encodeURIComponent(`${typeFilter}`)}&`;
  }

  if (subTypeFilter) {
    url += `SubTypeFilter=${encodeURIComponent(`${subTypeFilter}`)}&`;
  }
  url = url.replace(/[?&]$/, "");
  const response = await httpService.get(url);
  if (response.error) {
    return [];
  }
  return response.result;
};

const getCustomers = async ({ page, count }) => {
  let url = API.GET_CUSTOMER;
  if (page === null) {
    throw new Error("The parameter 'page' cannot be null.");
  } else if (page !== undefined) {
    url += `SkipCount=${encodeURIComponent(`${page * 10}`)}&`;
  }
  if (count === null) {
    throw new Error("The parameter 'count' cannot be null.");
  } else if (count !== undefined) {
    url += `MaxResultCount=${encodeURIComponent(`${count}`)}&`;
  }
  url = url.replace(/[?&]$/, "");
  const response = await httpService.get(url);
  if (response.error) {
    return [];
  }
  return response.result;
};
const getDetailCustomer = async id => {
  const url = `${API.GET_DETAIL_CUSTOMER}?id=${id}`;
  const response = await httpService.get(url);
  if (response.error) {
    return [];
  }
  return response.result;
};

const getBookings = async ({ page, count, customerId }) => {
  let url = API.GET_BOOKINGS;
  if (page === null) {
    throw new Error("The parameter 'page' cannot be null.");
  } else if (page !== undefined) {
    url += `SkipCount=${encodeURIComponent(`${page * 10}`)}&`;
  }
  if (count === null) {
    throw new Error("The parameter 'count' cannot be null.");
  } else if (count !== undefined) {
    url += `MaxResultCount=${encodeURIComponent(`${count}`)}&`;
  }

  if (customerId !== undefined) {
    url += `CustomerIdFilter=${encodeURIComponent(`${customerId}`)}&`;
  }
  url = url.replace(/[?&]$/, "");
  const response = await httpService.get(url);
  if (response.error) {
    return [];
  }
  return response.result;
};
const getDetailBooking = async id => {
  const url = `${API.GET_DETAIL_BOOKING}?id=${id}`;
  const response = await httpService.get(url);
  if (response.error) {
    return [];
  }
  return response.result;
};
const getEmployees = async name => {
  let url = API.GET_EMPOYLEES;
  if (name === null) {
    throw new Error("The parameter 'name' cannot be null.");
  } else if (name !== undefined) {
    url += `Filter=${encodeURIComponent(`${name}`)}&`;
  }
  url = url.replace(/[?&]$/, "");
  const response = await httpService.get(url);
  console.log("response", response);
  if (response.error) {
    return [];
  }
  return response.result;
};

const getGeneralInformation = async () => {
  let url = API.GET_GENERAL_INFORMATION;
  // if (name === null) {
  //   throw new Error("The parameter 'name' cannot be null.");
  // } else if (name !== undefined) {
  //   url += `Filter=${encodeURIComponent(`${name}`)}&`;
  // }
  url = url.replace(/[?&]$/, "");
  const response = await httpService.get(url);
  console.log("response", response);
  if (response.error) {
    return [];
  }
  return response.result;
};

const getAgents = async () => {
  let url = API.GET_AGENTS;
  url = url.replace(/[?&]$/, "");
  const response = await httpService.get(url);
  if (response.error) {
    return [];
  }
  return response.result;
};


const createAllocate = async (customerId, newUserId) => {
  const url = `${API.CREATE_ALLOCATE}?customerId=${customerId}&newUserId=${newUserId}`;
  const response = await httpService.post(url);
  if (!response) {
    return false;
  }
  return response;
};

const getPropertiesAndInformation = async ({
  agentId,
  projectId,
  blockFilter,
}) => {
  let url = API.GET_PROPERTY_AND_INFO;

  if (agentId) {
    url += `AgentId=${encodeURIComponent(`${agentId}`)}&`;
  }
  if (projectId !== undefined) {
    url += `ProjectId=${encodeURIComponent(`${projectId}`)}&`;
  }

  if (blockFilter) {
    url += `BlockFilter=${encodeURIComponent(`${blockFilter}`)}&`;
  }

  url += `MaxResultCount=${encodeURIComponent(`${1000}`)}&`;
  url = url.replace(/[?&]$/, "");
  const response = await httpService.get(url);
  if (response.error) {
    return [];
  }
  return response.result;
};

const scanBookingQRCode = async (bookingNo, propertyId) => {
  const url = `${API.SCAN_BOOKING_QRCODE}?bookingNo=${bookingNo}&propertyId=${propertyId}`;
  const response = await httpService.post(url);
  if (!response) {
    return false;
  }
  return response;
};

const scanSaleQRCode = async (saleNo, userId) => {
  const url = `${API.SCAN_SALE_QRCODE}?saleNo=${saleNo}&userId=${userId}`;
  const response = await httpService.post(url);
  if (!response) {
    return false;
  }
  return response;
};
const scanCheckBookingQRCode = async bookingNo => {
  const url = `${API.SCAN_CHECK_BOOKING_QRCODE}?bookingNo=${bookingNo}`;
  const response = await httpService.post(url);
  if (!response) {
    return false;
  }
  return response;
};

const confirmOTPBookingCode = async (otpCode, bookingNo, propertyId) => {
  const url = `${API.CONFIRM_OTP_BOOKING_CODE}?otpCode=${otpCode}&bookingNo=${bookingNo}&propertyId=${propertyId}`;
  console.log(url);
  const response = await httpService.post(url);
  if (!response) {
    return false;
  }
  return response;
};

const getPriorityList = async projectId => {
  const url = `${API.GET_PRIORITY_LIST}?ProjectId=${projectId}`;
  const response = await httpService.get(url);
  if (response.error) {
    return [];
  }
  return response.result;
};
const uploadImages = async (images, bookingId, type, imageDelId) => {
  let url = API.UPLOAD_BOOKING_FILE;
  if (bookingId) {
    url += `bookingId=${encodeURIComponent(`${bookingId}`)}&`;
  }
  if (imageDelId) {
    url += `lstImageIdToDelete=${encodeURIComponent(`${imageDelId}`)}&`;
  }
  if (type) {
    url += `type=${encodeURIComponent(`${type}`)}&`;
  }
  url = url.replace(/[?&]$/, "");
  const response = await httpService.post(url, images);
  if (!response) {
    return false;
  }
  return response;
};

const getNews = async ({ page, count }) => {
  let url = API.GET_NEWS;
  console.log(url);
  console.log(page);
  console.log(count);
  if (page === null) {
    throw new Error("The parameter 'page' cannot be null.");
  } else if (page !== undefined) {
    url += `SkipCount=${encodeURIComponent(`${page * 10}`)}&`;
  }
  if (count === null) {
    throw new Error("The parameter 'count' cannot be null.");
  } else if (count !== undefined) {
    url += `MaxResultCount=${encodeURIComponent(`${count}`)}&`;
  }
  url = url.replace(/[?&]$/, "");
  console.log(url);
  const response = await httpService.get(url);

  if (response.error) {
    return [];
  }
  return response.result;
};

const getDetailNews = async id => {
  const url = `${API.GET_DETAIL_NEWS}?id=${id}`;
  const response = await httpService.get(url);
  if (response.error) {
    return [];
  }
  return response.result;
};

const createBooking = async form => {
  const response = await httpService.post(API.CREATE_BOOKING, form);
  if (!response) {
    return false;
  }
  return response;
};
const createBookingReturnId = async form => {
  const response = await httpService.post(API.CREATE_BOOKING_RETURN_ID, form);
  if (!response) {
    return false;
  }
  return response;
};
const createAndEditCustomer = async form => {
  const response = await httpService.post(API.CREATE_CUSTOMER, form);
  if (!response) {
    return false;
  }
  return response;
};
const uploadCustomerImages = async form => {
  const response = await httpService.post(API.UPLOAD_CUSTOMER_FILE, form);
  if (!response) {
    return false;
  }
  return response;
};
const getLinkLiveAndView360 = async () => {
  const response = await httpService.get(API.GET_LINK_LIVE_STREAM_AND_VIEW_360);
  if (response.error) {
    return [];
  }
  return response.result;
}

const getListGift = async () => {
  let url = API.GET_GIFT;
  url = url.replace(/[?&]$/, "");
  const response = await httpService.get(url);
  console.log("getListGift", response);
  if (response.error) {
    return [];
  }
  return response.result;
};

const getCustomerWins = async () => {
  let url = API.GET_CUSTOMER_WIN;
  url = url.replace(/[?&]$/, "");
  const response = await httpService.get(url);
  console.log("getCustomerWins", response);
  if (response.error) {
    return [];
  }
  return response.result;
};
const saveCustomerWin = async form => {
  const response = await httpService.post(API.SAVE_CUSTOMER_WIN, form);
  if (!response) {
    return false;
  }
  return response;
};
const getETickets = async () => {
  let url = API.GET_ETICKETS;
  url = url.replace(/[?&]$/, "");
  const response = await httpService.get(url);
  if (response.error) {
    return [];
  }
  return response.result;
};
const getListCustomerLuckyWheels = async () => {
  let url = API.GET_LIST_CUSTOMER_LUCKY_WHEELS;
  url = url.replace(/[?&]$/, "");
  const response = await httpService.get(url);
  if (response.error) {
    return [];
  }
  return response.result;
};
const getETicketCustomer = async (giftId) => {
  let url = `${API.GET_ETICKET_CUSTOMER}&giftId=${giftId}`;
  const response = await httpService.get(url);
  if (response.error) {
    return [];
  }
  return response.result;
};
const getCustomerWinner = async () => {
  let url = API.GET_LIST_CUSTOMER_WINNER;
  const response = await httpService.get(url);
  if (response.error) {
    return [];
  }
  return response.result;
};
export default {
  login,
  getUser,
  signup,
  verifyOtp,
  resetPassword,
  updatePassword,
  getProjects,
  verifyForgotPasswordOtp,
  getBanner,
  getProjectDetailByGroup,
  getCustomers,
  getDetailCustomer,
  getBookings,
  getDetailBooking,
  getEmployees,
  getAgents,
  createAllocate,
  getPropertiesAndInformation,
  confirmOTPBookingCode,
  scanBookingQRCode,
  scanSaleQRCode,
  getPriorityList,
  uploadImages,
  getNews,
  getDetailNews,
  createBooking,
  createBookingReturnId,
  scanCheckBookingQRCode,
  createAndEditCustomer,
  uploadCustomerImages,
  getGeneralInformation,
  getLinkLiveAndView360,
  getListGift,
  getCustomerWins,
  saveCustomerWin,
  getETickets,
  getListCustomerLuckyWheels,
  getETicketCustomer,
  getCustomerWinner
};
