import React, { Fragment, useEffect, useState, useCallback, useContext } from "react";
import Header from 'commons/components/Header/Header'
import { useParams } from 'react-router-dom';
import "./CartPrivate.less";
import { Table, Spin, Select } from 'antd';
import useGetPropertiesAndInfo from "commons/hooks/useGetPropertiesAndInfo";
import tower from "assets/images/tower.svg";
const { Option } = Select;

export const CartPrivate = () => {
  let { id } = useParams();

  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([])
  const [blockFilter, setBlockName] = useState("");

  const handleChange = useCallback(blockName => {
    setBlockName(blockName);
  });

  const {
    loading,
    data: {
      numberOfApartment,
      numberOfFloor,
      properties,
      blocks,
      currentBlock,
      agentName
    },
  } = useGetPropertiesAndInfo({
    agentId: id,
    blockFilter
  });

  useEffect(() => {
    if (properties) {
      loadData();
    }
  }, [properties]);

  console.log(currentBlock);

  const loadData = () => {
    let dataColumns = [
      {
        title: () => {
          return (
            <div key="header-icon" className="item yellow">
              <img src={tower} />
            </div>);
        },
        width: 80,
        key: 'apartment',
        fixed: 'left',
        render: (text, record, index) => {
          return displayRowFirst(record, index);
        }
      }
    ];
    const dataApi = {
      tableData: [],
    };
    for (let i = 1; i <= numberOfApartment; i++) {
      dataColumns.push(
        {
          title: () => {
            return (
              <div key="header-icon" className="item yellow">
                <span>{i.toString().padStart(2, "0")}</span>
              </div>);
          },
          width: 150,
          key: `apartment${i}`,
          render: (text, record, index) => {
            return (displayRow(record, index, i - 1));
          }
        }
      )
    }

    const updatedItems = properties?.items.map(x => x.property);

    for (let i = 1; i <= numberOfFloor; i += 1) {
      const rowData = [];
      for (let j = 1; j <= numberOfApartment; j += 1) {
        const apartmentIndex = updatedItems.findIndex(
          x => x?.floor === i.toString() && x?.apartment === j.toString()
        );
        const apartment =
          apartmentIndex !== -1 ? updatedItems[apartmentIndex] : null;
        const attributes =
          apartmentIndex !== -1 ? properties?.items[apartmentIndex].attributes : [];
        if (apartment) {
          apartment.name = `${i.toString().padStart(2, "0")}${j
            .toString()
            .padStart(2, "0")}`;
          switch (apartment.status) {
            case "Available":
              rowData.push({
                id: `row${i}${j}`,
                title: `${currentBlock}.${i.toString().padStart(2, "0")}.${j
                  .toString()
                  .padStart(2, "0")} - ${apartment?.bed}PN`,
                color: "green",
                item: apartment,
                floor: `Tầng ${i}`,
                apartment: j,
                hasClick: true,
                attributes
              });
              break;
            case "InProgress":
              rowData.push({
                id: `row${i}${j}`,
                title: `${currentBlock}.${i.toString().padStart(2, "0")}.${j
                  .toString()
                  .padStart(2, "0")} - ${apartment?.bed}PN`,
                floor: `Tầng ${i}`,
                apartment: j,
                color: "process",
                attributes
                // hasClick: true,
              });
              break;
            case "Deposited":
              rowData.push({
                id: `row${i}${j}`,
                title: `${currentBlock}.${i.toString().padStart(2, "0")}.${j
                  .toString()
                  .padStart(2, "0")} - ${apartment?.bed}PN`,
                color: "sold",
                floor: `Tầng ${i}`,
                apartment: j,
                attributes
                // hasClick: true,
              });
              break;
            default:
              rowData.push({
                id: `row${i}${j}`,
                title: " ",
                description: " ",
                color: "",
                floor: `Tầng ${i}`,
                apartment: j,
                hasClick: false,
                attributes
              });
              break;
          }
        } else {
          rowData.push({
            id: `row${i}${j}`,
            title: " ",
            description: " ",
            color: "",
            floor: `Tầng ${i}`,
            apartment: j,
            hasClick: false,
            attributes
          });
        }
      }
      dataApi.tableData.push(rowData);
    }
    setColumns(dataColumns);
    setData(dataApi.tableData);
  };

  const displayRow = (items, index, floor) => {
    const item = items[floor];

    return (
      <div key={item?.id} className={`item ${item?.color}`}>
        <div>
          <span>{item?.title}</span>
          {item?.color ? <span>{item?.description}</span> : <span>&nbsp;</span>}

        </div>
      </div>
    )
  }
  const displayRowFirst = (items, index) => {
    const item = items[0];
    return (
      <div key={item?.id} className="item yellow">
        <div>
          <span>{item?.floor}</span>
        </div>
      </div>
    )
  }
  const displayOptions = () => {
    return (blocks.map(item => (
      <Option value={item}>Tháp {item}</Option>
    )));

  }

  return (
    <Fragment>
      <div className="container-fix">
        <div className="body-container">
          <Header></Header>
          <div className="main dark-bg animate__animated animate__fadeIn" id="list-room-page">
            <div className="container-fluid" >
              <div className="note">
                <h6>{agentName}</h6>
                <ul>
                  <li><span></span> Không có</li>
                  <li className="available"><span></span> Đang bán</li>
                  <li className="process"><span></span> Đang giao dịch</li>
                  <li className="sold"><span></span> Đã bán</li>

                </ul>

              </div>
              <div className="blocks">
                {blocks && (
                  <Select
                    onChange={handleChange}
                    style={{ width: 120, float: "right" }}
                    placeholder="Blocks"
                    defaultValue={currentBlock}
                  >
                    {displayOptions()}
                  </Select>)}
              </div>
              <div className="room-list">
                <Table
                  pagination={false}
                  loading={loading}
                  columns={columns}
                  dataSource={data}
                  scroll={{ x: 1200, y: 650 }}
                  locale={{
                    emptyText: loading ? (
                      <div css={{ height: 1200, width: 650 }} />
                    ) : (
                      <Spin tip="Loading..." />
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
