import React, { useContext, useMemo } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import './App.less';
import Layout from "./Layout";
import { AuthContext } from "context/AuthContext";
const PrivateRoute = ({ children, redirectTo, isAuth, path, isCheck, roleLevel, ...props }) => {
    if (!isAuth) {
        return <Navigate to={redirectTo} />;
    }
    if (isCheck && roleLevel !== 4) {
        return <Navigate to={redirectTo} />;
    }
    return children;
};
export const App = () => {
    const { user } = useContext(AuthContext);
    const { isAuth, roleLevel } = useMemo(() => {
        return { isAuth: Boolean(user.userName), roleLevel: user.roleLevel }
    }, [user])
    return (
        <Routes>
            <Route index path="/" element={<Layout.HomePage />} />
            <Route path="/login" element={<Layout.Login />} />
            <Route path="/cart" element={<PrivateRoute isAuth={isAuth} redirectTo={"/login"} children={<Layout.Cart />} />} />
            <Route path="/cart-public" element={<PrivateRoute isAuth={isAuth} isCheck={true} roleLevel={roleLevel} redirectTo={"/"} children={<Layout.CartPublic />} />} />
            <Route path="/cart-private/:id" element={<PrivateRoute isAuth={isAuth} redirectTo={"/login"} children={<Layout.CartPrivate />} />} />
            <Route path="/view-360" element={<PrivateRoute isAuth={isAuth} redirectTo={"/login"} children={<Layout.View360 />} />} />
            <Route path="/live-stream" element={<PrivateRoute isAuth={isAuth} redirectTo={"/login"} children={<Layout.LiveStream />} />} />
            <Route path="/lucky-draw" element={<PrivateRoute isAuth={isAuth} redirectTo={"/login"} children={<Layout.LuckyRaw />} />} />
            <Route path="/lucky-draw-customers" element={<PrivateRoute isAuth={isAuth} redirectTo={"/login"} children={<Layout.LuckyRawCustomers />} />} />
            <Route path="/lucky-draw-winners" element={<PrivateRoute isAuth={isAuth} redirectTo={"/login"} children={<Layout.LuckyRawWinners />} />} />
            <Route path="*" element={<Layout.HomePage />} />
        </Routes>
    );
};
export default App;