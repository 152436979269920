import React, { Fragment } from "react";
import logoRiverside from '../../assets/images/logo-riverside.png'
import  Header  from 'commons/components/Header/Header'
import "./HomePage.less";
export const HomePage = () => {
    return (
       <Fragment>
             <div className="container-fix bg-img">
                <div className="body-container">
                   <Header></Header>

                    <div className="main animate__animated animate__fadeIn" id="home-page">
                        <div className="container" >
                        <h2>SỰ KIỆN RA MẮT DỰ ÁN</h2>
                        <h3>EXCLUSIVE LAUCH EVENT OF</h3>
                        <div className="project">
                            <img src={logoRiverside} alt="riverside" />
                        </div>
                        </div>
                    </div>
            </div>
        </div>
       </Fragment>
    );
};
