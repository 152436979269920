import { useState, useEffect, useCallback } from "react";
import services from "commons/apis/index";
const useGetPropertiesAndInfo = ({ agentId, projectId, blockFilter }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [runApi, setRunApi] = useState(false);
  const getProperties = useCallback(async () => {
    try {
      setLoading(true);
      const items = await services.getPropertiesAndInformation({
        agentId,
        projectId,
        blockFilter,
      });
      if (!items) {
        return null;
      }
      console.log("items", items);
      setData(items);
      setRunApi(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [agentId, blockFilter, projectId])
  useEffect(() => {
    getProperties();
  }, [getProperties]);
  useEffect(() => {
    if(runApi){
      const interval = setInterval(() => {
        getProperties();
        console.log('This will run every second!');
        setRunApi(false);
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [getProperties, runApi]);
  return {
    data,
    loading,
  };
};
export default useGetPropertiesAndInfo;
