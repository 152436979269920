import { useState, useEffect, useCallback } from "react";
import services from "commons/apis/index";

const useGetListCustomerLuckyWheel = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const loadMoreDataCustomer = useCallback(async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const dataCustomer = await services.getListCustomerLuckyWheels();
      setData(dataCustomer);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [loading])
  useEffect(() => {
    loadMoreDataCustomer();
  }, []);
  return {
    data: data ?? [],
    loading,
    loadMoreDataCustomer
  };
};
export default useGetListCustomerLuckyWheel;
